<template>
  <v-data-table
    class="elevation-1"
    :footer-props="footerProps"
    :headers="headers"
    :items-per-page="itemsPerPage"
    :items="items"
    :loading="loading"
    :options="options"
    :server-items-length="totalItems"
    :show-select="showSelect"
    :single-select="singleSelect"
    :value="value"
    @input="e => $emit('input', e)"
    @update:options="e => $emit('update:options', e)"
    disable-sort="disable-sort"
  >
    <template v-slot:[`item.createdAt`]="{ item }"
      ><span>{{ $utils.calendar(item.createdAt) }}</span></template
    >
    <template v-slot:[`item.total`]="{ item }"
      ><span class="font-weight-medium">{{
        $utils.decimal(item.total, 2)
      }}</span></template
    >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip :class="statusColor(item.status)" small="small"
        ><span class="overline white--text">{{ item.status }}</span></v-chip
      >
    </template>
    <template
      v-slot:[`item.data-table-select`]="{ isSelected, select }"
      v-if="showSelect"
    >
      <v-btn @click="select(!isSelected)" text="text" icon="icon">
        <v-checkbox :value="isSelected"></v-checkbox>
      </v-btn>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
        class="mr-1"
        @click="() => $emit('item-click', item)"
        icon="icon"
        text="text"
        title="Editar"
      >
        <v-icon size="22">mdi-pencil</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "documents-data-table",
  props: {
    items: Array,
    itemsPerPage: Number,
    loading: Boolean,
    options: Object,
    showSelect: Boolean,
    singleSelect: Boolean,
    totalItems: Number,
    value: Array,
  },
  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 15],
    },
    headers: [
      { text: "Data", align: "start", value: "createdAt" },
      { text: "Cliente", align: "start", value: "customer.name" },
      { text: "Atendente", align: "start", value: "account.name" },
      { text: "Total (R$)", align: "end", value: "total" },
      { text: "Status", align: "end", value: "status" },
      { text: "Ações", align: "end", value: "action", sortable: false },
    ],
  }),

  methods: {
    statusColor(v) {
      switch (v.toLowerCase()) {
        case "novo":
          return "light-blue lighten-1";
        case "finalizado":
          return "accent";
        case "cancelado":
          return "grey";
        default:
          return "accent";
      }
    },
  },
};
</script>
