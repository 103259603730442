var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.localItems,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"show-select":_vm.showSelect,"single-select":_vm.singleSelect,"value":_vm.value,"disable-sort":"disable-sort"},on:{"click:row":function (e) { return _vm.$emit('item-click', e); },"input":function (e) { return _vm.$emit('input', e); },"update:options":function (e) { return _vm.$emit('update:options', e); }},scopedSlots:_vm._u([{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:_vm.getAvatar(item.service.name).color,attrs:{"title":item.service.name,"icon":"icon"},on:{"click":function($event){$event.stopPropagation();return _vm.selectField('service', item.service)}}},[_c('v-icon',{attrs:{"color":"white","size":"18"}},[_vm._v(_vm._s(_vm.getAvatar(item.service.name).icon))])],1)]}},{key:"item.animal",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"link":"link","small":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.selectField('animal', item.animal)}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.animal.name.toUpperCase()))])])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(!!item.customer.name)?_c('v-chip',{attrs:{"link":"link","small":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.selectField('customer', item.customer)}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.customer.name.toUpperCase()))])]):_vm._e()]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$utils.calendar(item.startedAt)))])]}},{key:"item.endedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$utils.calendar(item.endedAt)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"offset-y"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var value = ref.value;
return [_c('v-chip',_vm._g({class:_vm.statusColor(item.status),attrs:{"link":"link","small":"small"},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('span',{staticClass:"overline white--text"},[_vm._v(_vm._s(item.status))]),_c('v-icon',{staticClass:"white--text",attrs:{"right":"right","size":"20"}},[_vm._v(_vm._s(value ? "mdi-menu-up" : "mdi-menu-down"))])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.statusMenu),function(s){return _c('v-list-item',{key:s,on:{"click":function($event){return _vm.statusChange(item, s)}}},[_c('v-list-item-title',[_vm._v(_vm._s(s))])],1)}),1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status.toLowerCase() === 'finalizado' && !!item.customer.name)?_c('v-btn',{attrs:{"icon":"icon","text":"text","title":"Nova venda"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sellout-action', item)}}},[_c('v-icon',{attrs:{"size":22}},[_vm._v("mdi-briefcase-edit")])],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }