var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"show-select":_vm.showSelect,"single-select":_vm.singleSelect,"value":_vm.value,"disable-sort":"disable-sort"},on:{"click:row":function (e) { return _vm.$emit('item-click', e); },"input":function (e) { return _vm.$emit('input', e); },"update:options":function (e) { return _vm.$emit('update:options', e); }},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$utils.decimal(item.price, 2)))])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$utils.decimal(item.stock, 3)))])]}},(_vm.showSelect)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-btn',{attrs:{"text":"text","icon":"icon"},on:{"click":function($event){return select(!isSelected)}}},[_c('v-checkbox',{attrs:{"value":isSelected}})],1)]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }