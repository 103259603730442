import { db } from "../google/firebase";
import $store from "@/store";

export default async (ref, data) => {
  const snap = await db.collection($store.getters.COLLECTION_ITEMS)
    .where("code", "==", data.code)
    .get({ source: "cache" })
    .catch(() => null);

  let exists = false;

  snap.forEach(v => {
    if (v.id !== ref.id && !!v.get("code")) {
      exists = true;
    }
  });

  if (exists) {
    throw {
      msg: "Já existe um item com o código informado, utilize outro código",
      code: "code_exists",
    };
  }

  const batch = db.batch();
  batch.set(ref, data, { merge: true });
  return batch.commit();
};
