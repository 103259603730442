<template>
  <v-data-table
    class="elevation-1"
    :footer-props="footerProps"
    :headers="headers"
    :items-per-page="itemsPerPage"
    :items="localItems"
    :loading="loading"
    :options="options"
    :server-items-length="totalItems"
    :show-select="showSelect"
    :single-select="singleSelect"
    :value="value"
    @click:row="e => $emit('item-click', e)"
    @input="e => $emit('input', e)"
    @update:options="e => $emit('update:options', e)"
    disable-sort="disable-sort"
  >
    <template v-slot:[`item.service`]="{ item }">
      <v-btn
        :class="getAvatar(item.service.name).color"
        :title="item.service.name"
        @click.stop="selectField('service', item.service)"
        icon="icon"
      >
        <v-icon color="white" size="18">{{
          getAvatar(item.service.name).icon
        }}</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`item.animal`]="{ item }">
      <v-chip
        @click.stop="selectField('animal', item.animal)"
        link="link"
        small="small"
        ><span class="font-weight-bold">{{
          item.animal.name.toUpperCase()
        }}</span></v-chip
      >
    </template>
    <template v-slot:[`item.customer`]="{ item }">
      <v-chip
        @click.stop="selectField('customer', item.customer)"
        link="link"
        small="small"
        v-if="!!item.customer.name"
        ><span class="font-weight-bold">{{
          item.customer.name.toUpperCase()
        }}</span></v-chip
      >
    </template>
    <template v-slot:[`item.startedAt`]="{ item }"
      ><span>{{ $utils.calendar(item.startedAt) }}</span></template
    >
    <template v-slot:[`item.endedAt`]="{ item }"
      ><span>{{ $utils.calendar(item.endedAt) }}</span></template
    >
    <template v-slot:[`item.status`]="{ item }">
      <v-menu offset-y="offset-y">
        <template v-slot:activator="{ on, value }">
          <v-chip
            :class="statusColor(item.status)"
            @click.stop=""
            link="link"
            small="small"
            v-on="on"
            ><span class="overline white--text">{{ item.status }}</span>
            <v-icon class="white--text" right="right" size="20">{{
              value ? "mdi-menu-up" : "mdi-menu-down"
            }}</v-icon>
          </v-chip>
        </template>
        <v-list>
          <v-list-item
            @click="statusChange(item, s)"
            v-for="s in statusMenu"
            :key="s"
          >
            <v-list-item-title>{{ s }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
        @click.stop="$emit('sellout-action', item)"
        icon="icon"
        text="text"
        title="Nova venda"
        v-if="item.status.toLowerCase() === 'finalizado' && !!item.customer.name"
      >
        <v-icon :size="22">mdi-briefcase-edit</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { db } from "../../plugins/google/firebase";
import firestore from "../../plugins/firestore";

export default {
  name: "schedules-data-table",
  props: {
    items: Array,
    itemsPerPage: Number,
    loading: Boolean,
    localFilters: Object,
    options: Object,
    showSelect: Boolean,
    singleSelect: Boolean,
    totalItems: Number,
    value: Array,
  },

  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 15],
    },
    headers: [
      { text: "Serviço", align: "start", value: "service" },
      { text: "Inicia em", value: "startedAt" },
      { text: "Termina em", value: "endedAt" },
      { text: "Animal", align: "start", value: "animal" },
      { text: "Cliente", align: "start", value: "customer" },
      { text: "Status", align: "end", value: "status" },
      { text: "Venda", align: "end", value: "action" },
    ],
    statusMenu: ["Novo", "Em andamento", "Finalizado", "Cancelado"],
  }),

  computed: {
    localItems() {
      const v = this.localFilters;
      const animal = Object.values(v.animal);
      const customer = Object.values(v.customer);
      const service = Object.values(v.service);
      let items = JSON.parse(JSON.stringify(this.items));

      if (animal.length > 0) {
        const ids = animal.map((a) => a.id);
        items = items.filter((val) => ids.includes(val.animal.id));
      }

      if (customer.length > 0) {
        const ids = customer.map((a) => a.id);
        items = items.filter((val) => ids.includes(val.customer.id));
      }

      if (service.length > 0) {
        const ids = service.map((a) => a.id);
        items = items.filter((val) => ids.includes(val.service.id));
      }

      return items;
    },
  },

  methods: {
    getAvatar(name) {
      switch (name) {
        case "Banho":
          return { icon: "mdi-shower-head", color: "blue lighten-1" };
        case "Tosa":
          return { icon: "mdi-content-cut", color: "deep-orange lighten-1" };
        case "Embelezamento":
          return { icon: "mdi-auto-fix", color: "deep-purple darken-1" };
        case "Cirurgia":
          return { icon: "mdi-medical-bag", color: "light-blue lighten-1" };
        case "Internação":
          return { icon: "mdi-hospital-building", color: "cyan darken-1" };
        case "Vacinas":
          return { icon: "mdi-pill", color: "green darken-1" };
        case "Hospedagem":
          return { icon: "mdi-home-modern", color: "indigo darken-1" };
        case "Outros":
          return { icon: "mdi-playlist-edit", color: "grey darken-1" };
      }
    },

    selectField(key, v) {
      const event = JSON.parse(JSON.stringify(this.localFilters));
      event[key][v.id] = v;
      this.$emit("update:localFilters", event);
    },

    statusColor(v) {
      switch (v.toLowerCase()) {
        case "novo":
          return "light-blue lighten-1";
        case "em andamento":
          return "deep-orange lighten-1";
        case "finalizado":
          return "accent";
        case "cancelado":
          return "grey";
        default:
          return "accent";
      }
    },

    async statusChange(item, status) {
      try {
        this.$emit("update:isLoading", true);
        const ref = db
          .collection(this.$store.getters.COLLECTION_SCHEDULES)
          .doc(item.id);

        const doc = JSON.parse(JSON.stringify(item));
        doc.status = status;
        doc.updatedAt = new Date().toJSON();
        delete doc.id;

        await firestore.setSchedule(ref, doc);
      } catch (e) {
        this.$snackbar.show({
          title: e.items || "Não foi possível realizar a solicitação",
        });
      }
    },
  },
};
</script>
