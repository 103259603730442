import firebase, { db } from "../google/firebase";

export default (ref, data) => {
  return db.runTransaction(transaction => {
    return transaction.get(ref).then(prev => {
      const prevStatus = prev.get("status");
      const status = data.status;

      if (prevStatus !== status) {
        const s = data.type === "P" ? "Pago" : "Recebido";
        data.endedAt = status === s
          ? firebase.firestore.FieldValue.serverTimestamp()
          : null;
      }

      return transaction.set(ref, data);
    });
  });
};
