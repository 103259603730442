import { db } from "../google/firebase";
import $store from "@/store";
import parse from "./parseDocumentItems";

const setDocument = async (ref, item, total) => {
  const itemRef = db.collection($store.getters.COLLECTION_DOCUMENTS_ITEMS).doc(item.id);
  const batch = db.batch();

  const doc = parse({ item });
  batch.set(itemRef, doc);
  batch.update(ref, { total });
  return batch.commit();
};

const setSchedule = (ref, item) => {
  const itemRef = db.collection($store.getters.COLLECTION_DOCUMENTS_ITEMS).doc(item.id);

  return db.runTransaction(async transaction => {
    const prev = await transaction.get(ref);
    const doc = parse({ item, scheduleStatus: prev.get("status") });
    return transaction.set(itemRef, doc);
  });
};

export default (item, total) => {
  if (item.documentId) {
    const ref = db.collection($store.getters.COLLECTION_DOCUMENTS).doc(item.documentId);
    return setDocument(ref, item, total);
  } else {
    const ref = db.collection($store.getters.COLLECTION_SCHEDULES).doc(item.scheduleId);
    return setSchedule(ref, item);
  }
};
