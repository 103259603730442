import { db } from "@/plugins/google/firebase";

const getDataRef = path => {
  return db.collection(path);
};

function getFirst({ dataRef, sortByName, sortDescName, firstLimit, filters }) {
  if (filters && filters.owner) {
    return dataRef
      .where("owner", "==", null)
      .limit(firstLimit);
  }

  if (filters && filters.role) {
    return dataRef
      .where("role", "==", filters.role)
      .limit(firstLimit);
  }

  if (filters && filters.schedules) {
    return dataRef
      .where("startedAt", ">", filters.schedules.startedAt)
      .where("startedAt", "<", filters.schedules.endedAt)
      .limit(firstLimit);
  }

  return dataRef
    .orderBy(sortByName, sortDescName)
    .limit(firstLimit);
}

async function getNext({ dataRef, sortByName, sortDescName, lastVisible, itemsPerPage, source, filters }) {
  if (filters && filters.owner) {
    return await dataRef
      .where("owner", "==", null)
      .startAfter(lastVisible)
      .limit(itemsPerPage)
      .get({ source });
  }

  if (filters && filters.role) {
    return await dataRef
      .where("role", "==", filters.role)
      .startAfter(lastVisible)
      .limit(itemsPerPage)
      .get({ source });
  }

  if (filters && filters.schedules) {
    return dataRef
      .where("startedAt", ">", filters.schedules.startedAt)
      .where("startedAt", "<", filters.schedules.endedAt)
      .startAfter(lastVisible)
      .limit(itemsPerPage)
      .get({ source });
  }

  return await dataRef
    .orderBy(sortByName, sortDescName)
    .startAfter(lastVisible)
    .limit(itemsPerPage)
    .get({ source });
}

async function getTotal({ dataRef, source, filters, rnp }) {
  const getTotalSum = v => {
    if (!rnp) {
      return { size: v.size };
    }

    const sum = v.docs.reduce((accumulator, c) => {
      const item = c.data();
      const currentValue = item.type === "P" ? (item.value * -1) : item.value;
      return accumulator + currentValue;
    }, 0);

    return { size: v.size, sum };
  };

  if (filters && filters.owner) {
    return await dataRef
      .where("owner", "==", null)
      .get({ source })
      .then(v => getTotalSum(v));
  }

  if (filters && filters.role) {
    return await dataRef
      .where("role", "==", filters.role)
      .get({ source })
      .then(v => getTotalSum(v));
  }

  if (filters && filters.schedules) {
    return dataRef
      .where("startedAt", ">", filters.schedules.startedAt)
      .where("startedAt", "<", filters.schedules.endedAt)
      .get({ source })
      .then(v => getTotalSum(v));
  }

  return await dataRef.get({ source }).then(v => getTotalSum(v));
}

export default async ({
  collectionPath,
  defaultSortDesc = false,
  defaultSortLabel = "name",
  filters,
  options,
  source = "cache",
  totalItems,
}) => {
  const { sortBy, sortDesc, page, itemsPerPage } = options,
    sortByName = sortBy[0] || defaultSortLabel,
    sortDescName = sortDesc[0] || defaultSortDesc ? "desc" : "asc",
    firstLimit = page < 2 ? itemsPerPage : (page - 1) * itemsPerPage;

  const dataRef = getDataRef(collectionPath);
  const rnp = collectionPath.includes("receivables_payables");

  const total = (totalItems && !rnp)
    ? { size: totalItems }
    : await getTotal({ dataRef, source, filters, rnp });

  const first = getFirst({
    dataRef,
    filters,
    firstLimit,
    sortByName,
    sortDescName,
  });

  if (page < 2) {
    let items = [];
    const querySnapshot = await first.get({ source });
    querySnapshot.forEach(doc => {
      const item = doc.data();
      item.id = doc.id;
      items.push(item);
    });
    return { items, total };
  }

  const lastVisible = await first.get({ source }).then(v => v.docs[v.docs.length - 1]);

  const next = await getNext({
    dataRef,
    filters,
    itemsPerPage,
    lastVisible,
    sortByName,
    sortDescName,
    source,
  });

  let items = [];
  next.forEach(doc => {
    const item = doc.data();
    item.id = doc.id;
    items.push(item);
  });

  return { items, total };
};