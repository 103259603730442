import firebase, { db } from "../google/firebase";
import $store from "@/store";

export default (item) => {
  const ref = db.collection($store.getters.COLLECTION_DOCUMENTS_ITEMS).doc(item.id);
  return db.runTransaction(async transaction => {
    const doc = await transaction.get(ref);

    if (!doc.exists) {
      return;
    }

    if (doc.get("documentId")) {
      return transaction.update(ref, { scheduleId: firebase.firestore.FieldValue.delete() });
    } else {
      return transaction.delete(ref);
    }
  });
};
