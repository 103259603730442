import firebase, { db } from "../google/firebase";
import $store from "@/store";

const persistItemsStock = (batch, items, cmd) => {
  if (!items || !cmd) {
    return;
  }

  const factor = cmd === "f" ? -1 : 1;

  items.forEach(({ doc }) => {
    const ref = db.collection($store.getters.COLLECTION_ITEMS).doc(doc.itemId);
    batch.update(ref, {
      stock: firebase.firestore.FieldValue.increment(doc.quantity * factor),
    });
  });
};

const persistReceivables = async (batch, documentId, data, cmd) => {
  if (!cmd) {
    return;
  }

  if (cmd === "f") {
    const receivableRef = db.collection($store.getters.COLLECTION_RECEIVABLES_PAYABLES).doc();
    batch.set(receivableRef, {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      customer: data.customer,
      documentId: documentId,
      endedAt: null,
      name: `Venda para ${data.customer.name}`,
      status: "Pendente",
      type: "R",
      value: data.total,
    });
    return;
  }

  const refs = await db.collection($store.getters.COLLECTION_RECEIVABLES_PAYABLES)
    .where("documentId", "==", documentId)
    .get()
    .catch(() => null);

  if (!refs || !refs.size) {
    return;
  }

  refs.forEach(v => {
    if (v.data().status === "Pendente") {
      batch.delete(v.ref);
    }
  });
};

const setDocumentItems = (batch, items, inserting) => {
  if (inserting && items) {
    items.forEach(v => batch.set(v.ref, v.doc));
  }
};

export default async (ref, doc, { items, cmd, inserting }) => {
  const batch = db.batch();

  setDocumentItems(batch, items, inserting);
  persistItemsStock(batch, items, cmd);
  await persistReceivables(batch, ref.id, doc, cmd);

  batch.set(ref, doc);

  return batch.commit();
};
