export default ({ item, documentId, scheduleStatus }) => {
  const doc = {
    itemId: item.itemId,
    name: item.name,
    price: item.price,
    quantity: item.quantity,
    scheduleReady: !!item.scheduleReady,
  };

  if (scheduleStatus && scheduleStatus.toLowerCase() === "finalizado") {
    doc.scheduleReady = true;
  }

  if (documentId) {
    doc.documentId = documentId;
  } else if (item.documentId) {
    doc.documentId = item.documentId;
  }

  item.customerId && (doc.customerId = item.customerId);
  item.scheduleId && (doc.scheduleId = item.scheduleId);

  return doc;
};
