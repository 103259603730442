import firebase, { db } from "../google/firebase";
import $store from "@/store";

export default (item, total) => {
  const itemRef = db.collection($store.getters.COLLECTION_DOCUMENTS_ITEMS).doc(item.id);

  return db.runTransaction(async transaction => {
    const doc = await transaction.get(itemRef);

    if (!doc.exists) {
      return;
    }

    if (doc.get("documentId")) {
      const docRef = db.collection($store.getters.COLLECTION_DOCUMENTS).doc(item.documentId);
      transaction.update(docRef, { total });
    }

    if (doc.get("scheduleId")) {
      return transaction.update(itemRef, { documentId: firebase.firestore.FieldValue.delete() });
    } else {
      return transaction.delete(itemRef);
    }
  });
};
