import deleteDocumentItem from "./deleteDocumentItem";
import deleteReceivablesPayables from "./deleteReceivablesPayables";
import deleteScheduleItem from "./deleteScheduleItem";
import parseDocumentItems from "./parseDocumentItems";
import setDocument from "./setDocument";
import setDocumentItem from "./setDocumentItem";
import setItem from "./setItem";
import setReceivablesPayables from "./setReceivablesPayables";
import setSchedule from "./setSchedule";

export default {
  deleteDocumentItem,
  deleteReceivablesPayables,
  deleteScheduleItem,
  parseDocumentItems,
  setDocument,
  setDocumentItem,
  setItem,
  setReceivablesPayables,
  setSchedule,
};
