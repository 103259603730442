<template>
  <v-data-table
    class="elevation-1"
    :footer-props="footerProps"
    :headers="headers"
    :items-per-page="itemsPerPage"
    :items="items"
    :loading="loading"
    :options="options"
    :server-items-length="totalItems"
    :show-select="showSelect"
    :single-select="singleSelect"
    :value="value"
    @input="e => $emit('input', e)"
    @update:options="e => $emit('update:options', e)"
  >
    <template v-slot:[`item.type`]="{ item }">
      <v-icon
        :color="getTypeColor(item.type)"
        :title="getTypeTitle(item.type)"
        left="left"
        size="18"
        >{{ getTypeIcon(item.type) }}</v-icon
      >
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        :outlined="!isPendente(item)"
        @click.stop=""
        link="link"
        small="small"
        ><span :class="{ 'font-weight-medium': isPendente(item) }">{{
          getStatusTitle(item)
        }}</span></v-chip
      >
    </template>
    <template v-slot:[`item.createdAt`]="{ item }"
      ><span :class="getTextClass(item)">{{
        getCreatedAt(item.createdAt)
      }}</span></template
    >
    <template v-slot:[`item.endedAt`]="{ item }"
      ><span :class="getTextClass(item)">{{
        getEndedAt(item.endedAt)
      }}</span></template
    >
    <template v-slot:[`item.name`]="{ item }"
      ><span :class="getTextClass(item)">{{ item.name }}</span></template
    >
    <template v-slot:[`item.value`]="{ item }"
      ><span :class="getTextClass(item)">{{
        valueFormatted(item)
      }}</span></template
    >
    <template
      v-slot:[`item.data-table-select`]="{ isSelected, select }"
      v-if="showSelect"
    >
      <v-btn @click="select(!isSelected)" text="text" icon="icon">
        <v-checkbox :value="isSelected"></v-checkbox>
      </v-btn>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-btn
        class="mr-1"
        @click="() => $emit('item-click', item)"
        icon="icon"
        text="text"
        title="Editar"
      >
        <v-icon size="22">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <template v-slot:[`body.append`]="{ headers, items }">
      <tr>
        <td class="text-end" v-for="(item, i) in headers" :key="i">
          <span
            class="subtitle-1 font-weight-bold"
            v-if="item.value === 'value'"
            >{{ totalValues(items) }}</span
          >
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "receivables-payables-data-table",
  props: {
    items: Array,
    itemsPerPage: Number,
    loading: Boolean,
    options: Object,
    showSelect: Boolean,
    singleSelect: Boolean,
    sumItems: Number,
    totalItems: Number,
    value: Array,
  },
  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 15],
    },
    headers: [
      { text: "Tipo", align: "start", value: "type", sortable: false },
      { text: "Status", align: "start", value: "status", sortable: false },
      { text: "Data", align: "start", value: "createdAt" },
      { text: "Pagamento", align: "start", value: "endedAt" },
      { text: "Descrição", align: "start", value: "name", sortable: false },
      { text: "Valor (R$)", align: "end", value: "value", sortable: false },
      { text: "", align: "end", value: "action", sortable: false },
    ],
  }),

  methods: {
    getCreatedAt(v) {
      const d = v ? v.toDate() : "";
      return this.$utils.calendar(d, "date");
    },

    getEndedAt(v) {
      return v ? this.$utils.calendar(v.toDate(), "date") : "Pendente";
    },

    getStatusColor(v) {
      switch (v.type) {
        case "P":
          return "red accent-2";
        default:
          return "green";
      }
    },

    getStatusTitle(v) {
      switch (v.status) {
        case "Pendente":
          return v.type === "P" ? "A PAGAR" : "A RECEBER";
        default:
          return v.status.toUpperCase();
      }
    },

    valueFormatted(v) {
      if (v.type === "P") {
        return `(${this.$utils.decimal(v.value, 2)})`;
      }
      return this.$utils.decimal(v.value, 2);
    },

    getTextClass(v) {
      return v.status !== "Pendente"
        ? "secondary--text"
        : "secondary--text font-weight-bold";
    },

    getTypeColor(v) {
      return v === "P" ? "red accent-2" : "green lighten-1";
    },

    getTypeIcon(v) {
      return v === "P" ? "mdi-arrow-up-bold" : "mdi-arrow-down-bold";
    },

    getTypeTitle(v) {
      return v === "P" ? "Pagar" : "Receber";
    },

    isPendente(v) {
      return v.status === "Pendente";
    },

    totalValues() {
      return `Saldo ${this.$utils.decimal(this.sumItems, 2)}`;
    },
  },
};
</script>
