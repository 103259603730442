import { db } from "../google/firebase";
import $store from "@/store";

const getLogContent = (prev, doc) => {
  if (!prev.exists) {
    const res = {
      createdAt: doc.updatedAt,
      title: doc.service.name,
      animal: doc.animal.name,
      status: doc.status,
    };
    doc.comments && (res.comments = doc.comments);
    return res;
  }

  const data = prev.data();

  const res = {
    createdAt: doc.updatedAt,
    title: doc.service.name,
    animal: doc.animal.name,
  };

  (data.status != doc.status) && (res.status = doc.status);
  (data.comments != doc.comments) && (res.comments = doc.comments);
  (data.details != doc.details) && (res.details = doc.details);
  (data.anamnese != doc.anamnese) && (res.anamnese = doc.anamnese);

  if (Object.keys(res).length > 3) {
    return res;
  }

  return null;
};

const getItems = async (prev, doc) => {
  if (!prev.exists || prev.get("status") == doc.status) {
    return null;
  }

  return await db.collection($store.getters.COLLECTION_DOCUMENTS_ITEMS)
    .where("scheduleId", "==", prev.id)
    .get({ source: "cache" })
    .catch(() => null);
};

export default (ref, doc, items) => {
  const logsRef = db.collection($store.getters.COLLECTION_ANIMALS)
    .doc(doc.animal.id)
    .collection("logs")
    .doc();

  return db.runTransaction(async transaction => {
    const prev = await transaction.get(ref);
    const log = getLogContent(prev, doc);
    const updateItems = await getItems(prev, doc);

    log && transaction.set(logsRef, log);
    items && items.forEach(v => transaction.set(v.ref, v.doc));

    if (updateItems) {
      const scheduleReady = doc.status.toLowerCase() === "finalizado";
      updateItems.forEach(snap => transaction.update(snap.ref, { scheduleReady }));
    }

    transaction.set(ref, doc);
  });
};
