var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"show-select":_vm.showSelect,"single-select":_vm.singleSelect,"value":_vm.value,"disable-sort":"disable-sort"},on:{"input":function (e) { return _vm.$emit('input', e); },"update:options":function (e) { return _vm.$emit('update:options', e); }},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$utils.calendar(item.createdAt)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$utils.decimal(item.total, 2)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.statusColor(item.status),attrs:{"small":"small"}},[_c('span',{staticClass:"overline white--text"},[_vm._v(_vm._s(item.status))])])]}},(_vm.showSelect)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-btn',{attrs:{"text":"text","icon":"icon"},on:{"click":function($event){return select(!isSelected)}}},[_c('v-checkbox',{attrs:{"value":isSelected}})],1)]}}:null,{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"icon","text":"text","title":"Editar"},on:{"click":function () { return _vm.$emit('item-click', item); }}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }