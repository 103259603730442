import $store from "@/store";

export default (type) => {
  switch (type) {
    case "accounts":
      return $store.getters.COLLECTION_ACCOUNTS;
    case "animals":
      return $store.getters.COLLECTION_ANIMALS;
    case "documents":
      return $store.getters.COLLECTION_DOCUMENTS;
    case "items":
      return $store.getters.COLLECTION_ITEMS;
    case "schedules":
      return $store.getters.COLLECTION_SCHEDULES;
    case "receivables-payables":
      return $store.getters.COLLECTION_RECEIVABLES_PAYABLES;
    default:
      return null;
  }
};
