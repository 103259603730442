import { db } from "../google/firebase";

export default (ref) => {
  return db.runTransaction(transaction => {
    return transaction.get(ref).then(prev => {
      if (!prev.exists) {
        throw { msg: "Não foi possível remover, conta não encontrada" };
      }
      transaction.delete(ref);
    });
  });
};
