<template>
  <v-data-table
    class="elevation-1"
    :footer-props="footerProps"
    :headers="$vuetify.breakpoint.lgAndUp ? headers : headersMobile"
    :items-per-page="itemsPerPage"
    :items="items"
    :loading="loading"
    :options="options"
    :server-items-length="totalItems"
    :show-select="showSelect"
    :single-select="singleSelect"
    :value="value"
    @click:row="e => $emit('item-click', e)"
    @input="e => $emit('input', e)"
    @update:options="e => $emit('update:options', e)"
  >
    <template v-slot:[`item.birth`]="{ item }"
      ><span>{{ $utils.calendar(item.birth, "date") }}</span></template
    >
    <template v-slot:[`item.createdAt`]="{ item }"
      ><span>{{ $utils.calendar(item.createdAt) }}</span></template
    >
    <template
      v-slot:[`item.data-table-select`]="{ isSelected, select }"
      v-if="showSelect"
    >
      <v-btn @click="select(!isSelected)" text="text" icon="icon">
        <v-checkbox :value="isSelected"></v-checkbox>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "animals-data-table",
  props: {
    items: Array,
    itemsPerPage: Number,
    loading: Boolean,
    options: Object,
    showSelect: Boolean,
    singleSelect: Boolean,
    totalItems: Number,
    value: Array,
  },
  data: () => ({
    footerProps: {
      "items-per-page-options": [5, 10, 15],
    },
    headers: [
      { text: "Nome", align: "start", value: "name" },
      { text: "Espécie", align: "start", value: "species" },
      { text: "Raça", value: "breed", sortable: false },
      { text: "Nascimento", value: "birth" },
      { text: "Cliente", value: "owner.name" },
      { text: "Cadastro", value: "createdAt" },
    ],
    headersMobile: [
      { text: "Nome", align: "left", value: "name" },
      { text: "Espécie", align: "left", value: "species" },
      { text: "Raça", value: "breed", sortable: false },
    ],
  }),
};
</script>
