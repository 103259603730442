<template>
  <component
    :is="currentElement"
    :items-per-page="itemsPerPageComputed"
    :items="items"
    :loading="loading"
    :isLoading.sync="loading"
    :localFilters="localFilters"
    :options.sync="options"
    :server-items-length="totalItems"
    :show-select="showSelect"
    :single-select="singleSelect"
    :sumItems="sumItems"
    :type="type"
    :value="value"
    @input="e => $emit('input', e)"
    @item-click="e => $emit('item-click', e)"
    @sellout-action="e => $emit('sellout-action', e)"
    @update:localFilters="$emit('update:localFilters', $event)"
  ></component>
</template>

<script>
import AccountsDataTable from "./AccountsDataTable.vue";
import AnimalsDataTable from "./AnimalsDataTable.vue";
import DocumentsDataTable from "./DocumentsDataTable.vue";
import ItemsDataTable from "./ItemsDataTable.vue";
import ReceivablesPayablesDataTable from "./ReceivablesPayablesDataTable.vue";
import SchedulesDataTable from "./SchedulesDataTable.vue";

import { db } from "@/plugins/google/firebase";
import Events from "@/plugins/events";
import getCollectionData from "./js/getCollectionData";
import getCollectionPath from "./js/getCollectionPath";

const SOURCE = { source: "cache" };

export default {
  name: "data-table",
  components: {
    AccountsDataTable,
    AnimalsDataTable,
    DocumentsDataTable,
    ItemsDataTable,
    ReceivablesPayablesDataTable,
    SchedulesDataTable,
  },
  props: {
    filters: Object,
    itemsPerPage: Number,
    localFilters: Object,
    showSelect: Boolean,
    singleSelect: Boolean,
    type: String,
    value: Array,
  },

  data: () => ({
    items: [],
    loading: false,
    options: {},
    totalItems: 0,
    sumItems: 0,
    defaultSortLabel: "name",
  }),

  mounted() {
    Events.$on("searchData", this.handleSearchData);
    Events.$on("snapshot", this.handleUpdates);
    this.defaultSortLabel = this.getDefaultSortLabel();
    this.defaultSortDesc = this.getDefaultSortDesc();
  },

  beforeDestroy() {
    Events.$off("searchData", this.handleSearchData);
    Events.$off("snapshot", this.handleUpdates);
  },

  computed: {
    currentElement() {
      switch (this.type) {
        case "accounts":
          return "accounts-data-table";
        case "animals":
          return "animals-data-table";
        case "documents":
          return "documents-data-table";
        case "items":
          return "items-data-table";
        case "schedules":
          return "schedules-data-table";
        case "receivables-payables":
          return "receivables-payables-data-table";
        default:
          return "";
      }
    },
    itemsPerPageComputed() {
      if (this.itemsPerPage) {
        return this.itemsPerPage;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 10;
      }
      return 5;
    },
  },

  watch: {
    options: {
      handler() {
        getCollectionData({
          collectionPath: getCollectionPath(this.type),
          defaultSortDesc: this.defaultSortDesc,
          defaultSortLabel: this.defaultSortLabel,
          filters: this.filters,
          options: this.options,
          totalItems: this.totalItems,
        }).then((data) => {
          this.items = data.items;
          this.totalItems = data.total.size;
          this.sumItems = data.total.sum || 0;
        });
      },
      deep: true,
    },

    filters(v) {
      getCollectionData({
        collectionPath: getCollectionPath(this.type),
        defaultSortDesc: this.defaultSortDesc,
        defaultSortLabel: this.defaultSortLabel,
        filters: v,
        options: this.options,
        totalItems: this.totalItems,
      }).then((data) => {
        this.items = data.items;
        this.totalItems = data.total.size;
        this.sumItems = data.total.sum || 0;
      });
    },

    loading(v) {
      this.$emit("update:isLoading", v);
    },
  },

  methods: {
    getDefaultSortLabel() {
      switch (this.type) {
        case "schedules":
          return "startedAt";
        case "documents":
          return "createdAt";
        case "receivables-payables":
          return "createdAt";
        default:
          return "name";
      }
    },

    getDefaultSortDesc() {
      switch (this.type) {
        case "documents":
          return true;
        default:
          return false;
      }
    },

    handleSearchData({ value, type }) {
      if (type !== this.type) {
        return;
      }
      const collectionPath = getCollectionPath(this.type);
      if (!value) {
        getCollectionData({
          collectionPath,
          defaultSortDesc: this.defaultSortDesc,
          defaultSortLabel: this.defaultSortLabel,
          filters: this.filters,
          options: this.options,
          totalItems: this.totalItems,
        }).then((result) => {
          this.totalItems = 0;
          this.items = result.items;
          this.totalItems = result.total.size;
          this.sumItems = result.total.sum || 0;
        });
        return;
      }
      db.collection(collectionPath)
        .doc(value.doc.id)
        .get(SOURCE)
        .then((snapshot) => {
          const item = snapshot.data();
          item.id = snapshot.id;
          if (this.filters && this.filters.owner) {
            this.items = item.owner ? [] : [item];
            this.totalItems = item.owner ? 0 : 1;
            return;
          }
          if (this.filters && this.filters.role) {
            const val = item.role === this.filters.role;
            this.items = val ? [item] : [];
            this.totalItems = val ? 1 : 0;
            return;
          }
          this.items = [item];
          this.totalItems = 1;
        });
    },

    handleUpdates({ type, data }) {
      if (type !== this.type) {
        return;
      }
      getCollectionData({
        collectionPath: getCollectionPath(this.type),
        defaultSortDesc: this.defaultSortDesc,
        defaultSortLabel: this.defaultSortLabel,
        filters: this.filters,
        options: this.options,
        totalItems: data.size,
        source: "default",
      })
        .then((result) => {
          this.items = result.items;
          this.totalItems = result.total.size;
          this.sumItems = result.total.sum || 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
